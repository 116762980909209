import './list.scss';
import dataApi from '@/api/manage/exchange';
import member from '@/api/member/member';
import product from '@/api/product/product';
/*
 * 兑换日志
 *  */
export default {
  components: {},
  data() {
    return {
      activitySearchFo: {},
      activitySearchOp: {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            labelWidth: 0,
            label: '',
            prop: 'table',
            type: 'table',
            children: {
              border: true,
              searchMenuSpan: 8,
              column: [
                {
                  type: 'input',
                  label: '活动名称',
                  display: true,
                  prop: 'name',
                  required: true,
                  searchLabelWidth: 100,
                  search: true,
                  searchRules: [
                    {
                      required: true,
                      message: '请输入活动名称',
                      trigger: 'blur'
                    }
                  ],
                  rules: [
                    {
                      required: true,
                      message: '名称必须填写'
                    }
                  ]
                },
                {
                  type: 'tree',
                  label: '所属分类',
                  search: true,
                  props: {
                    label: 'label',
                    value: 'value'
                  },
                  dicUrl: `${this.$baseUrl}general/get_product_category`,
                  dicQuery: {},
                  dicMethod: 'post',
                  span: 12,
                  display: true,
                  prop: 'product_category_id',
                  required: true,
                  rules: [
                    {
                      required: true,
                      message: '所属分类必须填写'
                    }
                  ]
                }
              ]
            },
            formatter: (row) => {
              if (!row.name) return '';
              return `${row.name}`;
            },
            onLoad: ({ value, page }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                return;
              }
              // 请求列表数据
              product.getList
                .r({ page: page.currentPage, pageSize: page.pageSize })
                .then((res) => {
                  // 渲染数据
                  callback({
                    total: res.data.total,
                    data: [...res.data.list]
                  });
                });
            },
            props: {
              label: 'name',
              value: 'id'
            }
          }
        ]
      },
      memberSearchOp: {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            labelWidth: 0,
            label: '',
            prop: 'table',
            type: 'table',
            children: {
              border: true,
              searchMenuSpan: 8,
              column: [
                {
                  type: 'input',
                  label: '会员名称',
                  display: true,
                  prop: 'name',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  type: 'input',
                  label: '手机号',
                  display: true,
                  prop: 'mobile',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  label: '能量值',
                  prop: 'energy'
                },
                {
                  label: '赠送能量值',
                  prop: 'giving_energy'
                }
              ]
            },
            formatter: (row) => {
              if (!row.name) return '';
              return `${row.name}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                return;
              }
              // 请求列表数据
              member.getList.r({ ...data, page: page.currentPage, pageSize: page.pageSize }).then((res) => {
                // 渲染数据
                callback({
                  total: res.data.total,
                  data: [...res.data.list]
                });
              });
            },
            props: {
              label: 'name',
              value: 'id'
            }
          }
        ]
      },
      memberSearchFo: {},
      option: {
        data: [
          {
            title: '今日新增兑换笔数',
            count: 0
          },
          {
            title: '今日核销笔数',
            count: 0
          },
          {
            title: '今日待使用笔数',
            count: 0
          },
          {
            title: '今日消耗能量值数',
            count: 0
          }
        ]
      },
      childOption: {
        total: 10,
        align: 'center',
        ...this.$store.getters.tableConfig,
        card: false,
        menu: false,
        addBtn: false,
        filterBtn: false,
        selection: false,
        column: [
          {
            label: '订单渠道',
            prop: 'scenario'
          },
          {
            label: '备注',
            prop: 'note'
          },
          {
            type: 'radio',
            label: '兑换状态',
            value: '',
            dicData: [
              {
                label: '无效订单',
                value: '-1'
              },
              {
                label: '待付款',
                value: '1'
              },
              {
                label: '待发货',
                value: '2'
              },
              {
                label: '已发货',
                value: '3'
              },
              {
                label: '已完成',
                value: '4'
              },
              {
                label: '已关闭',
                value: '5'
              }
            ],
            display: true,
            prop: 'order_status'
          },
          {
            label: '操作人',
            display: true,
            prop: 'create_user'
          },
          {
            label: '操作时间',
            value: 1,
            display: true,
            prop: 'create_time'
          }
        ]
      },
      childData: [],
      page: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      drawerInfoVisible: false,
      drawerDelVisible: false,
      tableData: [],
      tbleOption: {
        total: 20,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        addBtn: false,
        viewBtn: false,
        editBtn: false,
        delBtn: false,
        ...this.$store.getters.tableConfig,
        selection: false,
        column: [
          {
            label: '流水号',
            addDisplay: false,
            editDisplay: false,
            search: true,
            prop: 'serial_no'
          },
          {
            label: '所属活动',
            addDisplay: false,
            editDisplay: false,
            search: true,
            width: 250,
            prop: 'activity_title'
          },
          // {
          //   label: '活动时间',
          //   prop: 'activity_plan'
          // },
          // {
          //   type: 'upload',
          //   label: '图片',
          //   prop: 'pic',
          //   detail: true,
          //   listType: 'picture-card',
          //   span: 24
          // },
          {
            label: '所属会员',
            prop: 'member_name',
            type: 'input',
            search: 'true'
          },
          {
            label: '订单金额',
            prop: 'subtotal',
            type: 'input',
            width: 100
          },
          {
            label: '兑换比',
            prop: 'prop'
          },
          {
            label: '有效价值',
            prop: 'actual_value'
          },
          {
            label: '支付时间',
            prop: 'pay_time'
          },
          {
            label: '订单渠道',
            type: 'select',
            display: true,
            search: 'true',
            prop: 'scenario',
            dicData: [
              {
                label: '前台',
                value: 2
              },
              {
                label: '小程序',
                value: 1
              }
            ]
          },
          {
            label: '使用状态',
            type: 'select',
            prop: 'status',
            search: true,
            // hide: true,
            dicData: [
              {
                label: '待支付',
                value: 1
              },
              {
                label: '待使用',
                value: 2
              },
              {
                label: '已使用',
                value: 3
              },
              {
                label: '已完成',
                value: 4
              },
              {
                label: '已关闭',
                value: 5
              },
              {
                label: '取消中',
                value: 6
              },
              {
                label: '退款中',
                value: 7
              },
              {
                label: '已取消',
                value: 8
              },
              {
                label: '无效订单',
                value: -1
              }
            ]
          },
          
          {
            label: '创建时间',
            prop: 'create_time'
          },
          {
            type: 'radio',
            label: '日期类型',
            value: '',
            dicData: [
              {
                label: '创建时间',
                value: 'create'
              },
              {
                label: '完成时间',
                value: 'update'
              },
              {
                label: '支付时间',
                value: 'pay_time'
              },
              {
                label: '核销时间',
                value: 'check_time'
              }
            ],
            hide: true,
            display: false,
            search: 'true',
            prop: 'date_type',
            span: 6
          },
          {
            type: 'datetime',
            searchRange: true,
            label: '日期范围',
            span: 8,
            hide: true,
            display: false,
            search: 'true',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            prop: 'datetime'
          }
        ]
      },
      searchData: {},
      formDa: {},
      drawerData: {},
      delDa: {}
    };
  },
  computed: {
    delOp() {
      return {
        span: 24,
        labelWidth: '120',
        submitBtn: true,
        emptyBtn: false,
        emptyText: '关闭',
        column: [
          {
            label: '流水号',
            type: 'input',
            disabled: true,
            prop: 'serial_no'
          },
          {
            label: '所属活动',
            type: 'input',
            disabled: true,
            prop: 'activity_title'
          },
          {
            label: '所属会员',
            type: 'input',
            disabled: true,
            prop: 'member_name'
          },
          {
            label: '可退还能量值',
            type: 'input',
            disabled: true,
            prop: 'refund_energy'
          },
          {
            label: '占位费类型',
            type: 'select',
            prop: 'charge_type',
            dicUrl: `${this.$baseUrl}general/get_dict`,
            dicMethod: 'post',
            dicFlag: true,
            filterable: false,
            multiple: false,
            dicQuery: {
              dict_type: 'perch'
            },
            rules: [
              {
                required: true,
                message: '请选额占位费',
                trigger: 'blur'
              }
            ],
            change: (val) => {
              if (val.value === '0') {
                this.delDa.custom = 0;
              } else if (val.value === 'custom') {
                this.delDa.custom = '';
              } else {
                this.delDa.custom = Number(val.value);
              }
            },
            value: '0'
          },
          {
            label: '占位费',
            type: 'input',
            disabled: this.delDa.charge_type !== 'custom',
            prop: 'custom',
            rules: [
              {
                required: true,
                message: '请输入自定义占位费',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '退还金额',
            type: 'input',
            formslot: true,
            disabled: true,
            prop: 'charge_fee'
          },
          {
            label: '说明',
            type: 'textarea',
            prop: 'remark'
          }
        ]
      };
    }
  },
  mounted() {},
  filters: {},
  methods: {
    vBtnShowCancel(val) {
      if (val.status === -1 || val.status === 3 || val.status === 4) {
        return false;
      }
      return true;
    },
    delSubmit(form, done) {
      if (form.refund_energy < Number(form.custom)) {
        done();
        this.$message.error('退还金额必须大于或等于0');
        return false;
      }
      dataApi.delExchange
        .r(form)
        .then(() => {
          this.drawerDelVisible = false;
          this.getList();
          this.$message.success('取消成功');
        })
        .catch(() => {});
      done();
    },
    rowCourse(row) {
      console.log(row);
      this.$confirm('是否立即排课?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dataApi.postArrange.r({ exchange_id: row.id }).then((res) => {
          console.log(res);
        });
      });
    },
    getChildData(id) {
      console.log(id);
      dataApi.getOperate.r({ exchange_id: id }).then((res) => {
        this.childData = [...res.data.list];
      });
    },
    showDrawer(row) {
      this.drawerData = { ...row };
      this.drawerInfoVisible = true;
    },
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
      dataApi.getStatistics.r().then((res) => {
        this.option.data[0].count = res.data.day_count;
        this.option.data[1].count = res.data.check_count;
        this.option.data[2].count = res.data.pre_count;
        this.option.data[3].count = res.data.energy_sum;
      });
    },
    // 取消订单
    rowDel(row) {
      dataApi.getone.r({ id: row.id }).then((res) => {
        this.delDa = res.data;
        this.drawerDelVisible = true;
      });
    },
    // 导出
    searchExcel(params) {
      if (this.activitySearchFo.table) {
        params.activity_id = this.activitySearchFo.table;
      }
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.start_time = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.member_name;
      delete params.activity_title;
      delete params.datetime;
      // 导出
      dataApi.getExport.r(params).then((res) => {
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.location.href = res.data;
        });
      });
    },
    // 筛选
    searchChange(params, done) {
      if (this.activitySearchFo.table) {
        params.activity_id = this.activitySearchFo.table;
      }
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.start_time = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;
      dataApi.getList.r(params).then((res) => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
      done();
    },
    // 筛选清空
    searchReset() {
      this.memberSearchFo.table = '';
      this.activitySearchFo.table = '';
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    rowView(row) {
      this.getInfo(row);
      this.drawerInfoVisible = true;
    },
    drawerInfoClose() {
      this.drawerInfoVisible = false;
    },
    drawerDelClose() {
      this.drawerDelVisible = false;
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
