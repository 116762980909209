<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-data-icons :option="option"></avue-data-icons>
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @refresh-change="getList"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
      <template
        slot="searchMenu"
        slot-scope="{ row, size }"
      >
        <el-button
          icon="el-icon-download"
          :size="size"
          @click="searchExcel(row,size)"
        >导出</el-button>
</template>
<template slot="activity_titleSearch">
  <div style="height: 32px;width: 100%;">
    <avue-form
      ref="form"
      :option="activitySearchOp"
      v-model="activitySearchFo"
    ></avue-form>
  </div>
</template>
<template slot="member_nameSearch">
  <div style="height: 32px;width: 100%">
    <avue-form
      ref="form"
      :option="memberSearchOp"
      v-model="memberSearchFo"
    ></avue-form>
  </div>
</template>
<template
  slot-scope="{ row }"
  slot="activity_title"
>
  <div style="width: 100%;display: flex;justify-content: space-between">
    <img
      style="width: 50px;height: 50px"
      :src="row.pic"
      alt=""
    >
      <span style="text-align: left">
        {{ row.activity_title }}<br> {{ row.activity_plan }}
      </span>
  </div>
</template>
  <template
    slot-scope="{ row }"
    slot="subtotal"
  >
  <div style="text-align: left">
    订单金额 &nbsp;
    <span style="color: #79bbff">{{ row.subtotal }}</span> <br> 优惠金额 -
    <span style="color: #f89898">{{ row.coupon_amount }}</span> <br> 实付金额 =
    <span style="color: #95d475">{{ row.actual_payment }}</span>
  </div>
</template>
    <template
      slot-scope="{type,size,row}"
      slot="menu"
    >
      <el-button
        icon="el-icon-view"
        :size="size"
        @click="showDrawer(row)"
        :type="type"
      >查看详情</el-button>
      <el-button icon="el-icon-view"
        :size="size"
        v-if="row.status === 2 && row.is_course > 1"
        @click="rowCourse(row)"
        :type="type">排课</el-button>
        <el-button
          icon="el-icon-delete"
          :size="size"
          v-if="vBtnShowCancel(row)"
          :type="type"
          @click.stop="rowDel(row)"
        >取消</el-button>
    </template>
</avue-crud>
          <el-drawer
            title="兑换详情"
            :before-close="drawerInfoClose"
            :visible.sync="drawerInfoVisible"
            size="80%"
          >
            <div class="details">
              <!--交易详情-->
              <div class="details-header">
                <div class="details-title">基础信息</div>
              </div>
              <div class="details-view">
                <div class="details-view-item">
                  <div class="details-view-item-label">流水号:</div>
                  <div class="details-view-item-value">{{ drawerData.serial_no}}</div>
                </div>
                <div class="details-view-item">
                  <div class="details-view-item-label">所属活动:</div>
                  <div class="details-view-item-value">
                    {{ drawerData.activity_title }} &nbsp;&nbsp; {{ drawerData.activity_plan }}&nbsp;&nbsp;
                    <img
                      style="width: 50px;height: 50px;vertical-align: middle"
                      :src="drawerData.pic"
                      alt=""
                    >
                  </div>
                </div>
        
                <div class="details-view-item">
                  <div class="details-view-item-label">订单渠道:</div>
                  <div class="details-view-item-value">{{ drawerData.scenario_text }}</div>
                </div>
                <div class="details-view-item">
                  <div class="details-view-item-label">使用状态:</div>
                  <div class="details-view-item-value">{{ drawerData.status_text }}</div>
                </div>
                <div class="details-view-item">
                  <div class="details-view-item-label">会员名:</div>
                  <div class="details-view-item-value">{{ drawerData.member_name}}</div>
                </div>
                <div class="details-view-item">
                  <div class="details-view-item-label">添加时间:</div>
                  <div class="details-view-item-value">{{ drawerData.create_time }}</div>
                </div>
                <div class="details-view-item">
                  <div class="details-view-item-label">支付时间:</div>
                  <div class="details-view-item-value">{{ drawerData.pay_time }}</div>
                </div>
                <div class="details-view-item">
                  <div class="details-view-item-label">更新时间:</div>
                  <div class="details-view-item-value">{{ drawerData.update_time }}</div>
                </div>
                <div class="details-view-item">
                  <div class="details-view-item-label">核销时间:</div>
                  <div class="details-view-item-value">{{ drawerData.check_time }}</div>
                </div>
                <div class="details-view-item">
                  <div class="details-view-item-label">兑换比:</div>
                  <div class="details-view-item-value">{{ drawerData.prop }}</div>
                </div>
                <div class="details-view-item">
                  <div class="details-view-item-label">有效价值:</div>
                  <div class="details-view-item-value">{{ drawerData.actual_value }}</div>
                </div>
                <div class="details-view-item">
                  <div class="details-view-item-label">订单金额:</div>
                  <div class="details-view-item-value">{{ drawerData.subtotal }}</div>
                </div>
                <div class="details-view-item">
                  <div class="details-view-item-label">优惠金额:</div>
                  <div class="details-view-item-value">{{ drawerData.coupon_amount }}</div>
                </div>
                <div class="details-view-item">
                  <div class="details-view-item-label">实付金额:</div>
                  <div class="details-view-item-value">{{ drawerData.actual_payment }}</div>
                </div>
              </div>
            </div>

            <div class="details">
              <!--交易详情-->
              <div class="details-header">
                <div class="details-title">支付日志</div>
              </div>
              <div class="details-view">
                <avue-crud
                  :data="childData"
                  :option="childOption"
                  @on-load="getChildData(drawerData.id)"
                >
                  </avue-crud>
              </div>
            </div>
            </el-drawer>

            <el-drawer
              title="取消兑换"
              :before-close="drawerDelClose"
              :visible.sync="drawerDelVisible"
              size="80%"
            >
              <avue-form
                ref="form"
                :option="delOp"
                v-model="delDa"
                @submit="delSubmit"
              >
    <template slot="charge_fee">
    <div v-if="delDa.charge_type === 'custom'">
      {{ delDa.refund_energy - delDa.custom }}
    </div>
    <div v-else>
      {{ delDa.refund_energy - delDa.custom * delDa.refund_energy }}
    </div>
  </template>
</avue-form>
</el-drawer>
</div>
</template>
<script>
import List from './list';

export default List;
</script>
