import http from '@/utils/http';

/**
 * 兑换
 */
export default {
  // 获取兑换列表
  getList: {
    p: 'post,/manage/exchange/getlist',
    r: (data) => http({ url: '/manage/exchange/getlist', method: 'post', data })
  },
  // 获取兑换列表
  getone: {
    p: 'post,/manage/exchange/getone',
    r: (data) => http({ url: '/manage/exchange/getone', method: 'post', data })
  },
  // 取消未使用的订单
  delExchange: {
    p: 'post,/manage/exchange/cancel',
    r: (data) => http({ url: '/manage/exchange/cancel', method: 'post', data })
  },
  // 能量值日志
  getEnergy: {
    p: 'post,/member/energy/getlist',
    r: (data) => http({ url: '/member/energy/getlist', method: 'post', data })
  },
  // 兑换日志数据展示
  getStatistics: {
    p: 'post,/manage/exchange/statistics',
    r: (data) =>
      http({ url: '/manage/exchange/statistics', method: 'post', data })
  },
  // 首页数据展示
  getTotal: {
    p: 'post,/work/panel/total',
    r: (data) => http({ url: '/work/panel/total', method: 'post', data })
  },
  getOperate: {
    p: 'post,/manage/exchange/operate',
    r: (data) => http({ url: '/manage/exchange/operate', method: 'post', data })
  },
  // 排课
  postArrange: {
    p: 'post,/manage/exchange/arrange',
    r: (data) => http({ url: '/manage/exchange/arrange', method: 'post', data })
  },
  // 导出
  getExport: {
    p: 'post,/manage/exchange/export',
    r: (data) => http({ url: '/manage/exchange/export', method: 'post', data })
  }
};
