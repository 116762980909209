import http from '@/utils/http';

/**
 * 产品管理
 */
export default {
  getList: {
    p: 'post,/manage/product/getlist',
    r: (data) => http({ url: '/manage/product/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/product/add',
    r: (data) => http({ url: '/manage/product/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/product/update',
    r: (data) => http({ url: '/manage/product/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/product/delete',
    r: (data) => http({ url: '/manage/product/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/product/getOne',
    r: (data) => http({ url: '/manage/product/getone', method: 'post', data })
  },
  getProductAttrValue: {
    p: 'post,/general/get_product_attr',
    r: (data) =>
      http({ url: '/general/get_product_attr', method: 'post', data })
  },
  getCourse: {
    p: 'post,/general/get_course',
    r: (data) => http({ url: '/general/get_course', method: 'post', data })
  },
  getSkuInfo: {
    p: 'post,/manage/product/attr',
    r: (data) => http({ url: '/manage/product/attr', method: 'post', data })
  },
  updateSku: {
    p: 'post,/manage/product/update_attr',
    r: (data) =>
      http({ url: '/manage/product/update_attr', method: 'post', data })
  },
  getCourseList: {
    p: 'post,/product/course/getlist',
    r: (data) => http({ url: '/product/course/getlist', method: 'post', data })
  },
  getCourseInfo: {
    p: 'post,/product/course/getone',
    r: (data) => http({ url: '/product/course/getone', method: 'post', data })
  },
  addCourse: {
    p: 'post,/product/course/add',
    r: (data) => http({ url: '/product/course/add', method: 'post', data })
  },
  delCourse: {
    p: 'post,/manage/course/delete',
    r: (data) => http({ url: '/manage/course/delete', method: 'post', data })
  },
  getProductCourse: {
    p: 'post,/general/get_product_course',
    r: (data) =>
      http({ url: '/general/get_product_course', method: 'post', data })
  },
  getSignUpList: {
    p: 'post,/manage/signup/list',
    r: (data) => http({ url: '/manage/signup/list', method: 'post', data })
  }
};
